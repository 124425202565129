import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";

// mui components
import { IconButton, InputLabel, Stack } from "@mui/material";

// mui icons
import {
	ArrowBackOutlined,
	ArrowForwardOutlined,
	AddOutlined,
	DragHandleOutlined,
	HelpOutlineOutlined,
} from "@mui/icons-material";

// custom components
import RequiredError from "../../required-error";
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import MaskedInput from "../../custom-input/masked-input";
import SecondarySelect from "../../custom-select/secondary-select";
import PrimaryButton from "../../buttons/primary-button";
import SecondaryButton from "../../buttons/secondary-button";
import CustomDivider from "../../custom-divider";
import Heading5 from "../../typography/heading-05";
import Heading6 from "../../typography/heading-06";
import Body3 from "../../typography/body-03";
import Body2 from "../../typography/body-02";

// api slices
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";
import { useUpdateFieldsMutation } from "../../../features/update-fields/updateFieldsApiSlice";

//reducer slices
import { selectUserDetails } from "../../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../../features/admin/impersonated-user/impersonatedUserSlice";

// helper utils
import {
	YNOptions,
	lpcValueOptions,
	bpcValueOptions,
	stateOptions,
	vestingMethodOptions,
} from "../../../utils/select-options";
import { LOAN_CHANNEL_CONSTANTS } from "../../../utils/common-constants";
import { selectedUserWithLoanChannel } from "../../../utils/common-helpers";

// custom modal
import LoaderModal from "../../modal/loader-modal";
import HelpTextModal from "../../modal/help-text-modal";
import ConfirmationModal from "../../modal/confirmation-modal";

// custom styles
import styles from "./index.module.scss";

const LoanFeeApplication = ({
	setLoanApplicationStage,
	loanGuid,
	currentStage,
}) => {
	const [updateFields, { isLoading: isUpdatingFields }] =
		useUpdateFieldsMutation();
	const [field, { isLoading: isFetchingFields }] = useFieldMutation();

	const params = useParams();

	const [coBorrowerName, setCoBorrowerName] = useState("");

	const [closingDate, setClosingDate] = useState("");
	const [purchaseContractCloseDate, setPurchaseContractCloseDate] =
		useState("");
	const [vestingMethod, setVestingMethod] = useState("");
	const [vestingFull, setVestingFull] = useState("");

	// const [companyTitle, setCompanyTitle] = useState("");
	// const [officerName, setOfficerName] = useState("");
	// const [officerEmail, setOfficerEmail] = useState("");
	// const [officerPhone, setOfficerPhone] = useState("");
	// const [companyAddress, setCompanyAddress] = useState("");
	// const [companyZipcode, setCompanyZipcode] = useState("");
	// const [companyCity, setCompanyCity] = useState("");
	// const [companyState, setCompanyState] = useState("");

	const [escrowCompanyName, setEscrowCompanyName] = useState("");
	const [escrowOfficerName, setEscrowOfficerName] = useState("");
	const [escrowOfficerEmail, setEscrowOfficerEmail] = useState("");
	const [escrowOfficerPhone, setEscrowOfficerPhone] = useState("");
	const [escrowCompanyAddress, setEscrowCompanyAddress] = useState("");
	const [escrowCompanyZipcode, setEscrowCompanyZipcode] = useState("");
	const [escrowCompanyCity, setEscrowCompanyCity] = useState("");
	const [escrowCompanyState, setEscrowCompanyState] = useState("");

	const [borrowerPoa, setBorrowerPoa] = useState("");
	const [coBorrowerPoa, setCoBorrowerPoa] = useState("");

	const [occupancy, setOccupancy] = useState("");
	const [loanPurpose, setLoanPurpose] = useState("");
	const [cashOutProceeds, setCashOutProceeds] = useState("");
	const [propertyOccupiedFamily, setPropertyOccupiedFamily] = useState("");

	const [lpcValue, setLpcValue] = useState("");
	const [lpcAmount, setLpcAmount] = useState("");
	const [bpcValue, setBpcValue] = useState("");
	const [bpcAmount, setBpcAmount] = useState("");

	const [helpText, setHelpText] = useState("");
	const [extraHelpText, setExtraHelpText] = useState("");
	const [helpTextOpen, setHelpTextOpen] = useState(false);

	const [confirmModalText, setConfirmModalText] = useState("");
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);

	const handleOpenHelpText = () => {
		setHelpTextOpen(true);
	};

	const handleCloseHelpText = () => {
		setHelpTextOpen(false);
	};

	const handleOpenConfirmModal = () => {
		setConfirmModalVisible(true);
	};

	const handleCloseConfirmModal = () => {
		setConfirmModalVisible(false);
	};

	const handleSubmitConfirmModal = () => {
		setConfirmModalVisible(false);
		handleSubmitApplication();
	};

	const [creditReport, setCreditReport] = useState("");
	const [appraisalFee, setAppraisalFee] = useState("");
	const [secondAppraisalFee, setSecondAppraisalFee] = useState("");
	const [processingFee, setProcessingFee] = useState("");
	const [thirdPartyProcessingFee, setThirdPartyProcessingFee] = useState("");

	const [serviceCreditReport, setServiceCreditReport] = useState("");
	const [serviceAppraisalFee, setServiceAppraisalFee] = useState("");
	const [serviceSecondAppraisalFee, setServiceSecondAppraisalFee] =
		useState("");
	const [serviceProcessingFee, setServiceProcessingFee] = useState("");
	const [serviceThirdPartyProcessingFee, setServiceThirdPartyProcessingFee] =
		useState("");

	const [error, setError] = useState("");
	const [inputError, setInputError] = useState("");

	const [compensationPlan, setCompensationPlan] = useState("");

	const [loanOrganization, setLoanOrganization] = useState("");

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	const isExecutiveOrManager =
		userDetails?.user_roles?.includes("executive") ||
		userDetails?.user_roles?.includes("manager") ||
		userDetails?.user_roles?.includes("AE") ||
		userDetails?.user_roles?.includes("AM");

	const handlePrev = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(currentStage - 1);
	};

	const updateLoanFeeFields = async () => {
		console.log("50 loanGuid:", loanGuid);
		console.log("50 params:", params?.id);

		let valuesArr = [
			{ id: 763, value: purchaseContractCloseDate },
			{ id: 2966, value: closingDate },
			// { id: 411, value: companyTitle },
			// { id: 416, value: officerName },
			// { id: 88, value: officerEmail },
			// { id: 417, value: officerPhone },
			// { id: 412, value: companyAddress },
			// { id: 413, value: companyCity },
			// { id: 1174, value: companyState },
			// { id: 414, value: companyZipcode },
			{ id: 610, value: escrowCompanyName },
			{ id: 611, value: escrowOfficerName },
			{ id: 87, value: escrowOfficerEmail },
			{ id: 615, value: escrowOfficerPhone },
			{ id: 612, value: escrowCompanyAddress },
			{ id: 613, value: escrowCompanyCity },
			{ id: 1175, value: escrowCompanyState },
			{ id: 614, value: escrowCompanyZipcode },
			{ id: "CX.CPL.USINGPOA.BORROWER", value: borrowerPoa },
			{ id: "CX.CPL.USINGPOA.COBORROWER", value: coBorrowerPoa },

			{ id: 640, value: creditReport },
			{ id: 641, value: appraisalFee },
			{ id: "NEWHUD.X138", value: secondAppraisalFee },
			{ id: "NEWHUD.X136", value: processingFee },
			{ id: "NEWHUD.X1285", value: thirdPartyProcessingFee },

			{ id: "NEWHUD2.X1139", value: creditReport },
			{ id: "NEWHUD2.X1106", value: appraisalFee },
			{ id: "NEWHUD2.X1304", value: secondAppraisalFee },
			{ id: "NEWHUD2.X1238", value: processingFee },
			{ id: "NEWHUD2.X875", value: thirdPartyProcessingFee },

			{ id: 624, value: serviceCreditReport },
			{ id: 617, value: serviceAppraisalFee },
			{ id: "NEWHUD.X1052", value: serviceSecondAppraisalFee },
			{ id: "NEWHUD.X1050", value: serviceProcessingFee },
			{ id: "NEWHUD.X1284", value: serviceThirdPartyProcessingFee },
			{
				id: "NEWHUD.X1287",
				value: "Broker",
			},
			{ id: "CX.CPL.BUSPURPOSECASHOUT", value: cashOutProceeds, lock: true },
			{ id: "CX.CPL.OCCUPIEDBY", value: propertyOccupiedFamily, lock: true },
		];

		if (secondAppraisalFee !== "") {
			valuesArr.push({ id: "NEWHUD.X128", value: "2nd Appraisal Fee" });
		}

		if (processingFee !== "") {
			valuesArr.push({ id: "NEWHUD.X126", value: "Processing Fee" });
		}

		if (thirdPartyProcessingFee !== "") {
			valuesArr.push({ id: "NEWHUD.X1283", value: "ThirdPartyProcessingFee" });
		}

		if (
			vestingMethod === "Sole Ownership" ||
			vestingMethod === "Tenancy in Common" ||
			vestingMethod === "Tenancy by the Entirety" ||
			vestingMethod === "As Joint Tenants With Right of Survivorship"
		) {
			valuesArr.push({ id: "33", value: vestingMethod });
		} else if (
			vestingMethod === "A Limited Liability Company" ||
			vestingMethod === "A Corporation" ||
			vestingMethod === "An Inter Vivos Trust" ||
			vestingMethod === "A Trust"
		) {
			valuesArr.push({ id: "CX.PORTAL.CORPTRUSTORGTYP", value: vestingMethod });
			valuesArr.push({ id: "33", value: "" });
		}

		if (
			vestingMethod === "Sole Ownership" ||
			vestingMethod === "Tenancy in Common" ||
			vestingMethod === "Tenancy by the Entirety" ||
			vestingMethod === "As Joint Tenants With Right of Survivorship"
		) {
			valuesArr.push({ id: "CX.PORTAL.FINAL.VESTING", value: vestingFull });
		} else if (
			vestingMethod === "A Limited Liability Company" ||
			vestingMethod === "A Corporation" ||
			vestingMethod === "An Inter Vivos Trust" ||
			vestingMethod === "A Trust"
		) {
			valuesArr.push({ id: "CX.PORTAL.CORPTRUSTNAME", value: vestingFull });
		}

		valuesArr.push({ id: "389", value: bpcValue });
		valuesArr.push({ id: "1620", value: bpcAmount });

		try {
			const updateFieldValues = await updateFields({
				loan_guid: loanGuid || params?.id,
				field_values: valuesArr,
			}).unwrap();
			console.log("90 file res:", updateFieldValues);
			if (
				updateFieldValues?.response === "Field values updated successfully."
			) {
				if (loanGuid !== undefined && loanGuid !== "") {
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: "smooth",
					});
					setLoanApplicationStage(currentStage + 1);
				} else if (params?.id !== undefined && params?.id !== "") {
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: "smooth",
					});
					setLoanApplicationStage(currentStage + 1);
				}
			} else {
				console.log("90 file err else:", updateFieldValues?.response);
				setError(updateFieldValues?.response);
			}
		} catch (err) {
			console.log("90 file err catch:", err);
		}
	};

	const calculateBusinessDays = (startDate, numBusinessDays) => {
		let currentDate = startDate;
		let daysAdded = 0;

		while (daysAdded < numBusinessDays) {
			currentDate = currentDate.plus({ days: 1 });
			// Skip weekends (Saturday: 6, Sunday: 7)
			if (currentDate.weekday < 6) {
				daysAdded++;
			}
		}
		return currentDate;
	};

	const checkClosingAndPurchaseCloseDate = () => {
		if (closingDate && purchaseContractCloseDate) {
			const closingDateObj = DateTime.fromJSDate(closingDate); // full date with time
			const purchaseContractCloseDateObj = DateTime.fromJSDate(
				purchaseContractCloseDate
			); // full date with time

			const closingDateOnly = closingDateObj.startOf("day");
			const purchaseContractCloseDateOnly =
				purchaseContractCloseDateObj.startOf("day");

			const today = DateTime.now();
			const tenBusinessDaysFromToday = calculateBusinessDays(today, 10);
			const tenBusinessDaysDateOnly = tenBusinessDaysFromToday.startOf("day");

			// Check if Closing Date is after Purchase Contract Close Date
			if (closingDateOnly > purchaseContractCloseDateOnly) {
				setConfirmModalText(
					"The Closing Date entered is after the Purchase Contract Close Date. Is this correct?"
				);
				handleOpenConfirmModal();
				return;
			}

			// Check if either date is less than 10 business days from today's date
			if (
				closingDateOnly < tenBusinessDaysDateOnly ||
				purchaseContractCloseDateOnly < tenBusinessDaysDateOnly
			) {
				setConfirmModalText(
					"The Closing Date and/or the Purchase Contract Close Date entered is < 10 business days from today’s date. Is this correct?"
				);
				handleOpenConfirmModal();
				return;
			}
		} else {
			console.log(
				"LoanFeeApplication: checkClosingAndPurchaseCloseDate: Closing Date or Purchase Contract Close Date is missing."
			);
			handleSubmitApplication();
		}
	};

	const handleSubmitApplication = async () => {
		console.log("502 params id:", params?.id);
		console.log("502 loan id:", loanGuid);

		setInputError(false);

		// required fields fee
		if (
			!(
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
			) &&
			(closingDate === null || closingDate === undefined || closingDate === "")
		) {
			setInputError(true);
			setError("Please provide a value for all fields highlighted above.");
			return;
		}

		if (
			vestingMethod === undefined ||
			vestingMethod === "" ||
			borrowerPoa === undefined ||
			borrowerPoa === ""
		) {
			setInputError(true);
			setError("Please provide a value for all fields highlighted above.");
			return;
		}

		if (coBorrowerName !== undefined && coBorrowerName !== "") {
			if (coBorrowerPoa === undefined || coBorrowerPoa === "") {
				setInputError(true);
				setError("Please provide a value for all fields highlighted above.");
				return;
			}
		}

		if (
			(selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.CB ||
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.WHS) &&
			compensationPlan === "Borrower Paid"
		) {
			if (
				bpcValue === undefined ||
				bpcValue === "" ||
				bpcAmount === undefined ||
				bpcAmount === ""
			) {
				setInputError(true);
				setError("Please provide a value for all fields highlighted above.");
				return;
			}
		}

		if (occupancy === "Investor") {
			if (
				cashOutProceeds === undefined ||
				cashOutProceeds === "" ||
				propertyOccupiedFamily === undefined ||
				propertyOccupiedFamily === ""
			) {
				setInputError(true);
				setError("Please provide a value for all fields highlighted above.");
				return;
			}
		}

		if (loanGuid !== undefined && loanGuid !== "") {
			updateLoanFeeFields();
		} else if (params?.id !== undefined && params?.id !== "") {
			updateLoanFeeFields();
		} else {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
			setLoanApplicationStage(currentStage + 1);
		}
	};

	const findFieldValue = (fieldValues, fieldId) => {
		return fieldValues?.find((o) => o.fieldId === fieldId)?.value;
	};

	const updateField = (fieldValues) => {
		console.log("88 field values:", fieldValues);

		setCoBorrowerName(findFieldValue(fieldValues, "4004"));

		setClosingDate(findFieldValue(fieldValues, "2966"));
		setPurchaseContractCloseDate(findFieldValue(fieldValues, "763"));

		let corpTrustOrgType = findFieldValue(
			fieldValues,
			"CX.PORTAL.CORPTRUSTORGTYP"
		);
		let vestingField = findFieldValue(fieldValues, "33");

		if (corpTrustOrgType !== "") {
			setVestingMethod(corpTrustOrgType);
		} else if (vestingField !== "") {
			setVestingMethod(vestingField);
		}

		let finalVestingField = findFieldValue(
			fieldValues,
			"CX.PORTAL.FINAL.VESTING"
		);
		let trustName = findFieldValue(fieldValues, "CX.PORTAL.CORPTRUSTNAME");

		if (finalVestingField !== "") {
			setVestingFull(finalVestingField);
		} else if (trustName !== "") {
			setVestingFull(trustName);
		}

		// setCompanyTitle(findFieldValue(fieldValues, "411"));
		// setOfficerName(findFieldValue(fieldValues, "416"));
		// setOfficerEmail(findFieldValue(fieldValues, "88"));
		// setOfficerPhone(findFieldValue(fieldValues, "417"));
		// setCompanyAddress(findFieldValue(fieldValues, "412"));
		// setCompanyCity(findFieldValue(fieldValues, "413"));
		// setCompanyState(findFieldValue(fieldValues, "1174"));
		// setCompanyZipcode(findFieldValue(fieldValues, "414"));

		let escrowCompanyName = findFieldValue(fieldValues, "610");
		let escrowOfficerName = findFieldValue(fieldValues, "611");
		let escrowOfficerEmail = findFieldValue(fieldValues, "87");
		let escrowOfficerPhone = findFieldValue(fieldValues, "615");
		let escrowCompanyAddress = findFieldValue(fieldValues, "612");
		let escrowCompanyCity = findFieldValue(fieldValues, "613");
		let escrowCompanyState = findFieldValue(fieldValues, "1175");
		let escrowCompanyZipcode = findFieldValue(fieldValues, "614");

		if (
			escrowCompanyName !== "" ||
			escrowOfficerName !== "" ||
			escrowOfficerEmail !== "" ||
			escrowOfficerPhone !== "" ||
			escrowCompanyAddress !== "" ||
			escrowCompanyCity !== "" ||
			escrowCompanyState !== "" ||
			escrowCompanyZipcode !== ""
		) {
			setEscrowCompanyName(escrowCompanyName);
			setEscrowOfficerName(escrowOfficerName);
			setEscrowOfficerEmail(escrowOfficerEmail);
			setEscrowOfficerPhone(escrowOfficerPhone);
			setEscrowCompanyAddress(escrowCompanyAddress);
			setEscrowCompanyCity(escrowCompanyCity);
			setEscrowCompanyState(escrowCompanyState);
			setEscrowCompanyZipcode(escrowCompanyZipcode);
		}

		setBorrowerPoa(findFieldValue(fieldValues, "CX.CPL.USINGPOA.BORROWER"));

		setCoBorrowerPoa(findFieldValue(fieldValues, "CX.CPL.USINGPOA.COBORROWER"));

		setCreditReport(findFieldValue(fieldValues, "640"));
		setAppraisalFee(findFieldValue(fieldValues, "641"));
		setSecondAppraisalFee(findFieldValue(fieldValues, "NEWHUD.X138"));
		setProcessingFee(findFieldValue(fieldValues, "NEWHUD.X136"));
		setThirdPartyProcessingFee(findFieldValue(fieldValues, "NEWHUD.X1285"));

		setServiceCreditReport(findFieldValue(fieldValues, "624"));
		setServiceAppraisalFee(findFieldValue(fieldValues, "617"));

		setLpcValue(findFieldValue(fieldValues, "NEWHUD.X223"));
		setLpcAmount(findFieldValue(fieldValues, "NEWHUD.X224"));

		setBpcValue(findFieldValue(fieldValues, "389"));
		setBpcAmount(findFieldValue(fieldValues, "1620") || "0.00");

		setCompensationPlan(findFieldValue(fieldValues, "LCP.X1"));

		setLoanOrganization(findFieldValue(fieldValues, "TPO.X14"));

		let occupancyValue = findFieldValue(fieldValues, "1811");
		let loanPurposeValue = findFieldValue(fieldValues, "19");

		setOccupancy(occupancyValue);
		setLoanPurpose(loanPurposeValue);

		if (
			occupancyValue !== "Investor" ||
			loanPurposeValue === "Purchase" ||
			loanPurposeValue === "NoCash-Out Refinance"
		) {
			setCashOutProceeds("N");
		} else {
			setCashOutProceeds(
				findFieldValue(fieldValues, "CX.CPL.BUSPURPOSECASHOUT")
			);
		}

		if (
			occupancyValue === "PrimaryResidence" ||
			occupancyValue === "SecondHome"
		) {
			setPropertyOccupiedFamily("Y");
		} else {
			setPropertyOccupiedFamily(
				findFieldValue(fieldValues, "CX.CPL.OCCUPIEDBY")
			);
		}
	};

	const getFieldValues = async (loanId) => {
		console.log("175 loan id:", loanId);
		try {
			const getValues = await field({
				loan_guid: loanId,
				fields:
					"763,2966,33,CX.PORTAL.CORPTRUSTORGTYP,CX.PORTAL.FINAL.VESTING,CX.PORTAL.CorpTrustName,411,416,88,417,412,413,1174,414,610,611,87,615,612,613,1175,614,CX.CPL.USINGPOA.BORROWER,CX.CPL.USINGPOA.COBORROWER,NEWHUD.X251,NEWHUD.X254,650,644,651,645,40,41,43,44,1782,1783,1787,1788,1792,1793,NEWHUD.X223,NEWHUD.X224,389,1620,640,641,NEWHUD.X138,NEWHUD.X136,NEWHUD.X1285,TPO.X61,624,617,4004,LCP.X1,TPO.X14,1811,19,CX.CPL.BUSPURPOSECASHOUT,CX.CPL.OCCUPIEDBY",
			}).unwrap();
			console.log("175 all fields:", getValues?.response);
			if (getValues?.response?.length > 0) {
				updateField(getValues?.response);
			}
		} catch (err) {
			console.log("584 error:", err);
		}
	};

	useEffect(() => {
		console.log("160 loan Guid:", loanGuid);
		if (loanGuid !== undefined && loanGuid !== "") {
			getFieldValues(loanGuid);
		}
	}, [loanGuid]);

	let regex = /\(([^)]+)\)/;

	const loanChannel = isExecutiveOrManager
		? loanOrganization?.match(regex)?.[1]?.toUpperCase()
		: selectedUserWithLoanChannel(userDetails, impersonatedUser)?.loan_channel;

	const is_loan_cb_whs =
		selectedUserWithLoanChannel(userDetails, impersonatedUser)?.loan_channel ===
			LOAN_CHANNEL_CONSTANTS.CB ||
		selectedUserWithLoanChannel(userDetails, impersonatedUser)?.loan_channel ===
			LOAN_CHANNEL_CONSTANTS.WHS ||
		(isExecutiveOrManager && loanChannel === LOAN_CHANNEL_CONSTANTS.CB) ||
		(isExecutiveOrManager && loanChannel === LOAN_CHANNEL_CONSTANTS.WHS);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{/* Loan Type Section */}
				{is_loan_cb_whs && (
					<>
						<Stack direction="column" className={styles.cardHeaderContainer}>
							<Heading5 text="Loan Type" fontType="semibold" />
							<Body3 text="Provide details about the loan" />
						</Stack>

						<Stack
							direction="row"
							className={styles.formContainer + " " + styles.datesContainer}
						>
							{/* Closing date */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="closing-date-picker">
									<Heading6
										text="Closing Date"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<DatePicker
									id="closing-date-picker"
									className={
										inputError &&
										(closingDate === null ||
											closingDate === undefined ||
											closingDate === "")
											? styles.errorDatepicker
											: styles.datepicker
									}
									value={closingDate || ""}
									onChange={setClosingDate}
									minDate={new Date()}
									disabled={false}
									error={
										inputError &&
										(closingDate === null ||
											closingDate === undefined ||
											closingDate === "")
									}
								/>
							</Stack>

							{/* Purchase contract close date */}
							{loanPurpose === "Purchase" && (
								<Stack direction="column" className={styles.inputContainer}>
									<InputLabel htmlFor="purchase-contract-close-date-picker">
										<Heading6
											text="Purchase Contract Close Date"
											fontType="semibold"
											extraClass={styles.inputHeader}
										/>
									</InputLabel>

									<DatePicker
										id="purchase-contract-close-date-picker"
										className={styles.datepicker}
										value={purchaseContractCloseDate || ""}
										onChange={setPurchaseContractCloseDate}
										minDate={new Date()}
										disabled={false}
									/>
								</Stack>
							)}
						</Stack>

						<CustomDivider />
					</>
				)}

				{/* Fee reimbursement Section */}
				{is_loan_cb_whs && (
					<>
						<Stack direction="column" className={styles.cardHeaderContainer}>
							<Heading5 text="Fee reimbursement" fontType="semibold" />
							<Body3 text="Do you need any fees reimbursed back to you that you incurred on behalf of the borrower(s)?" />
						</Stack>

						<Stack
							direction="column"
							className={styles.formContainer + " " + styles.feeFormContainer}
						>
							{/* credit report */}
							<Stack direction="row" className={styles.formSectionContainer}>
								<Body2
									text="Credit Report:"
									extraClass={styles.sectionHeader}
								/>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="credit-report-fee">
											<Heading6
												text="Fees"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<MaskedInput
											id="credit-report-fee"
											placeholder="$"
											type="currency"
											fullWidth={true}
											value={creditReport}
											onInputChange={setCreditReport}
										/>
									</Stack>

									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="credit-report-service-provider">
											<Heading6
												text="Service Provider"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<OutlinedTextInput
											id="credit-report-service-provider"
											placeholder="Enter service provider"
											fullWidth={true}
											value={serviceCreditReport}
											handleChange={(event) =>
												setServiceCreditReport(event.target.value)
											}
										/>
									</Stack>
								</Stack>
							</Stack>

							{/* appraisal Fee: */}
							<Stack direction="row" className={styles.formSectionContainer}>
								<Body2
									text="Appraisal Fee:"
									extraClass={styles.sectionHeader}
								/>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="appraisal-fee">
											<Heading6
												text="Fees"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<MaskedInput
											id="appraisal-fee"
											placeholder="$"
											type="currency"
											fullWidth={true}
											value={appraisalFee}
											onInputChange={setAppraisalFee}
										/>
									</Stack>

									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="appraisal-fee-service-provider">
											<Heading6
												text="Service Provider"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<OutlinedTextInput
											id="appraisal-fee-service-provider"
											placeholder="Enter service provider"
											fullWidth={true}
											value={serviceAppraisalFee}
											handleChange={(event) =>
												setServiceAppraisalFee(event.target.value)
											}
										/>
									</Stack>
								</Stack>
							</Stack>

							{/* Second Appraisal Fee: */}
							<Stack direction="row" className={styles.formSectionContainer}>
								<Body2
									text="Second Appraisal Fee:"
									extraClass={styles.sectionHeader}
								/>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="second-appraisal-fee">
											<Heading6
												text="Fees"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>
										<MaskedInput
											id="second-appraisal-fee"
											placeholder="$"
											type="currency"
											fullWidth={true}
											value={secondAppraisalFee}
											onInputChange={setSecondAppraisalFee}
										/>
									</Stack>

									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="second-appraisal-fee-service-provider">
											<Heading6
												text="Service Provider"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<OutlinedTextInput
											id="second-appraisal-fee-service-provider"
											placeholder="Enter service provider"
											fullWidth={true}
											value={serviceSecondAppraisalFee}
											handleChange={(event) =>
												setServiceSecondAppraisalFee(event.target.value)
											}
										/>
									</Stack>
								</Stack>
							</Stack>

							{/* processing Fee: */}
							<Stack direction="row" className={styles.formSectionContainer}>
								<Body2
									text="Processing Fee:"
									extraClass={styles.sectionHeader}
								/>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="processing-fee">
											<Heading6
												text="Fees"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<MaskedInput
											id="processing-fee"
											placeholder="$"
											type="currency"
											fullWidth={true}
											value={processingFee}
											onInputChange={setProcessingFee}
										/>
									</Stack>

									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="processing-fee-service-provider">
											<Heading6
												text="Service Provider"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<OutlinedTextInput
											id="processing-fee-service-provider"
											placeholder="Enter service provider"
											fullWidth={true}
											value={serviceProcessingFee}
											handleChange={(event) =>
												setServiceProcessingFee(event.target.value)
											}
										/>
									</Stack>
								</Stack>
							</Stack>

							{/* third Party Processing Fee: */}
							<Stack direction="row" className={styles.formSectionContainer}>
								<Body2
									text="Third Party Processing Fee:"
									extraClass={styles.sectionHeader}
								/>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="third-party-processing-fee">
											<Heading6
												text="Fees"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<MaskedInput
											id="third-party-processing-fee"
											placeholder="$"
											type="currency"
											fullWidth={true}
											value={thirdPartyProcessingFee}
											onInputChange={setThirdPartyProcessingFee}
										/>
									</Stack>

									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="third-party-processing-service-provider">
											<Heading6
												text="Service Provider"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<OutlinedTextInput
											id="third-party-processing-service-provider"
											placeholder="Enter service provider"
											fullWidth={true}
											value={serviceThirdPartyProcessingFee}
											handleChange={(event) =>
												setServiceThirdPartyProcessingFee(event.target.value)
											}
										/>
									</Stack>
								</Stack>
							</Stack>
						</Stack>

						<CustomDivider />
					</>
				)}

				{/* Vesting Section */}
				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Vesting" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>
				<Stack direction="row" className={styles.formContainer}>
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-vesting-method">
							<Heading6
								text="How will the transaction be vested?"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-vesting-method"
							fullWidth={true}
							displayEmpty={true}
							value={vestingMethod || ""}
							handleChange={(event) => setVestingMethod(event.target.value)}
							options={vestingMethodOptions}
							error={
								inputError &&
								(vestingMethod === undefined || vestingMethod === "")
							}
						/>
					</Stack>

					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="input-vesting-wrriten">
							<Heading6
								text="Vesting fully written as:"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-vesting-wrriten"
							placeholder="Type here"
							fullWidth={true}
							value={vestingFull}
							handleChange={(event) => setVestingFull(event.target.value)}
						/>
					</Stack>
				</Stack>

				<CustomDivider />

				{/* Compensation Section */}
				{is_loan_cb_whs && (
					<>
						<Stack direction="column" className={styles.cardHeaderContainer}>
							<Heading5 text="Compensation" fontType="semibold" />
							<Body3 text="Provide details about the loan" />
						</Stack>

						<Stack
							direction="column"
							className={styles.formContainer + " " + styles.feeFormContainer}
						>
							{/* LPC */}
							<Stack direction="row" className={styles.formSectionContainer}>
								<Stack
									flexDirection="row"
									alignItems="center"
									className={styles.compensationHeaderContainer}
								>
									<Body2 text="LPC:" extraClass={styles.compensationHeader} />
								</Stack>

								<Stack justifyContent="center" alignItems="center">
									<DragHandleOutlined className={styles.lpcIcon} />
								</Stack>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.lpcContainer
										}
									>
										<InputLabel htmlFor="select-lpc-percentage">
											<Heading6
												text="LPC Percentage"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<SecondarySelect
											id="select-lpc-percentage"
											fullWidth={true}
											displayEmpty={true}
											value={lpcValue || ""}
											handleChange={(event) => setLpcValue(event.target.value)}
											options={lpcValueOptions}
											disabled={true}
										/>
									</Stack>
								</Stack>

								<Stack justifyContent="center" alignItems="center">
									<AddOutlined className={styles.lpcIcon} />
								</Stack>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.lpcContainer
										}
									>
										<InputLabel htmlFor="input-lpc-amount">
											<Heading6
												text="LPC Dollars"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<MaskedInput
											id="input-lpc-amount"
											placeholder="LPC Amount ($)"
											type="currency"
											value={lpcAmount || ""}
											onInputChange={setLpcAmount}
											disabled={true}
										/>
									</Stack>
								</Stack>
							</Stack>

							{/* BPC */}
							<Stack direction="row" className={styles.formSectionContainer}>
								<Stack
									flexDirection="row"
									alignItems="center"
									className={styles.compensationHeaderContainer}
								>
									<Body2
										text="Total BPC Compensation"
										extraClass={styles.compensationHeader}
									/>
								</Stack>

								<Stack justifyContent="center" alignItems="center">
									<DragHandleOutlined className={styles.bpcIcon} />
								</Stack>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.bpcContainer
										}
									>
										<InputLabel htmlFor="select-bpc-percentage">
											<Heading6
												text="BPC in percentage"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<SecondarySelect
											id="select-bpc-percentage"
											displayEmpty={true}
											value={bpcValue || ""}
											handleChange={(event) => setBpcValue(event.target.value)}
											options={bpcValueOptions}
											disabled={compensationPlan !== "Borrower Paid"}
											error={
												inputError &&
												compensationPlan === "Borrower Paid" &&
												(bpcValue === undefined || bpcValue === "")
											}
										/>
									</Stack>
								</Stack>

								<Stack justifyContent="center" alignItems="center">
									<AddOutlined className={styles.bpcIcon} />
								</Stack>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.bpcContainer
										}
									>
										<Stack direction="row">
											<InputLabel htmlFor="input-bpc-amount">
												<Heading6
													text="BPC in dollars"
													fontType="semibold"
													extraClass={styles.inputHeader}
												/>
											</InputLabel>

											<IconButton
												className={styles.helperIconBtn}
												onClick={() => {
													setHelpText(
														"Values entered in this field will be in addition to the “BPC in percentage” amount charged to the borrower. If the BPC compensation is based solely on a percentage, enter $0.00 in this field."
													);
													setExtraHelpText(
														"For example: If the loan amount is $100,000 the “BPC in percentage” is set to 1%, and you enter $1,000 in the “BPC in dollars” field, the borrower will be charged a total of $2,000 ($1,000 from the dollar amount + $1,000 from the percentage-based amount)."
													);
													handleOpenHelpText();
												}}
											>
												<HelpOutlineOutlined className={styles.helperIcon} />
											</IconButton>
										</Stack>

										<MaskedInput
											id="input-bpc-amount"
											placeholder="$"
											type="currency"
											value={bpcAmount}
											onInputChange={setBpcAmount}
											disabled={compensationPlan !== "Borrower Paid"}
											error={
												inputError &&
												compensationPlan === "Borrower Paid" &&
												(bpcAmount === undefined || bpcAmount === "")
											}
										/>
									</Stack>
								</Stack>
							</Stack>
						</Stack>

						<CustomDivider />
					</>
				)}

				{/* Escrow Section */}
				{is_loan_cb_whs && (
					<>
						<Stack direction="column" className={styles.cardHeaderContainer}>
							<Heading5 text="Escrow" fontType="semibold" />
							<Body3 text="Provide details about the escrow" />
						</Stack>

						<Stack direction="row" className={styles.formContainer}>
							{/* escrow company name */}
							<Stack
								direction="column"
								className={
									styles.inputContainer + " " + styles.fullWidthContainer
								}
							>
								<InputLabel htmlFor="input-company-name">
									<Heading6
										text="Company Name"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="input-company-name"
									fullWidth={true}
									value={escrowCompanyName}
									handleChange={(event) =>
										setEscrowCompanyName(event.target.value)
									}
								/>
							</Stack>

							{/* escrow company address */}
							<Stack
								direction="column"
								className={
									styles.inputContainer + " " + styles.fullWidthContainer
								}
							>
								<InputLabel htmlFor="input-company-address-line">
									<Heading6
										text="Escrow Company Address"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="input-company-address-line"
									fullWidth={true}
									value={escrowCompanyAddress}
									handleChange={(event) =>
										setEscrowCompanyAddress(event.target.value)
									}
								/>
							</Stack>

							{/* escrow company city */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="input-company-city">
									<Heading6
										text="City"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="input-company-city"
									fullWidth={true}
									value={escrowCompanyCity}
									handleChange={(event) =>
										setEscrowCompanyCity(event.target.value)
									}
								/>
							</Stack>

							{/* escrow company state */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="select-state">
									<Heading6
										text="State"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<SecondarySelect
									id="select-state"
									displayEmpty={true}
									value={escrowCompanyState || ""}
									handleChange={(event) =>
										setEscrowCompanyState(event.target.value)
									}
									options={stateOptions}
								/>
							</Stack>

							{/* escrow company zipcode */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="input-company-zipcode">
									<Heading6
										text="Zipcode"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="input-company-zipcode"
									fullWidth={true}
									value={escrowCompanyZipcode}
									handleChange={(event) =>
										setEscrowCompanyZipcode(event.target.value)
									}
								/>
							</Stack>

							{/* escrow officer name */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="input-officer-name">
									<Heading6
										text="Escrow Officer Name"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="input-officer-name"
									fullWidth={true}
									value={escrowOfficerName}
									handleChange={(event) =>
										setEscrowOfficerName(event.target.value)
									}
								/>
							</Stack>

							{/* escrow officer phone */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="input-officer-phone">
									<Heading6
										text="Escrow Officer Cell Phone Number"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id="input-officer-phone"
									placeholder="(XXX) XXX-XXXX"
									type="phone"
									value={escrowOfficerPhone}
									onInputChange={setEscrowOfficerPhone}
								/>
							</Stack>

							{/* escrow officer name */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="input-officer-email">
									<Heading6
										text="Escrow Officer Email ID"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="input-officer-email"
									fullWidth={true}
									value={escrowOfficerEmail}
									handleChange={(event) =>
										setEscrowOfficerEmail(event.target.value)
									}
								/>
							</Stack>
						</Stack>

						<CustomDivider />
					</>
				)}

				{/* POA Section */}
				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="POA" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>
				<Stack direction="row" className={styles.formContainer}>
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-poa-borrower">
							<Heading6
								text="Will POA be used for Borrower?"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-poa-borrower"
							fullWidth={true}
							displayEmpty={true}
							value={borrowerPoa || ""}
							handleChange={(event) => setBorrowerPoa(event.target.value)}
							options={YNOptions}
							error={
								inputError && (borrowerPoa === undefined || borrowerPoa === "")
							}
						/>
					</Stack>

					{coBorrowerName !== undefined && coBorrowerName !== "" && (
						<Stack
							direction="column"
							className={styles.inputContainer + " " + styles.inlineContainer}
						>
							<InputLabel htmlFor="select-poa-co-borrower">
								<Heading6
									text="Will POA be used for Co Borrower?"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-poa-co-borrower"
								fullWidth={true}
								displayEmpty={true}
								value={coBorrowerPoa || ""}
								handleChange={(event) => setCoBorrowerPoa(event.target.value)}
								options={YNOptions}
								error={
									inputError &&
									(coBorrowerPoa === undefined || coBorrowerPoa === "")
								}
							/>
						</Stack>
					)}
				</Stack>

				{occupancy === "Investor" && (
					<>
						<CustomDivider />

						{/* Business Purpose Section */}
						<Stack direction="column" className={styles.cardHeaderContainer}>
							<Heading5 text="Business Purpose" fontType="semibold" />
							<Body3 text="Provide details about the loan" />
						</Stack>
						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="select-cashout-business-purpose">
									<Heading6
										text="Cash Out Proceeds being utilized for Business Purposes"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<SecondarySelect
									id="select-cashout-business-purpose"
									fullWidth={true}
									displayEmpty={true}
									value={cashOutProceeds || ""}
									handleChange={(event) =>
										setCashOutProceeds(event.target.value)
									}
									options={YNOptions}
									error={
										inputError &&
										(cashOutProceeds === undefined || cashOutProceeds === "")
									}
									disabled={
										loanPurpose === "Purchase" ||
										loanPurpose === "NoCash-Out Refinance"
									}
								/>
							</Stack>

							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="select-property-occupied-family">
									<Heading6
										text="Will the property be Occupied by a Family Member?"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<SecondarySelect
									id="select-property-occupied-family"
									fullWidth={true}
									displayEmpty={true}
									value={propertyOccupiedFamily || ""}
									handleChange={(event) =>
										setPropertyOccupiedFamily(event.target.value)
									}
									options={YNOptions}
									error={
										inputError &&
										(propertyOccupiedFamily === undefined ||
											propertyOccupiedFamily === "")
									}
									disabled={
										occupancy === "PrimaryResidence" ||
										occupancy === "SecondHome"
									}
								/>
							</Stack>
						</Stack>
					</>
				)}
			</Stack>

			{/* loan flow error */}
			{error !== "" && (
				<RequiredError
					headerText={
						error.includes("is currently locked") ? "" : "Missing fields"
					}
					text={
						error.includes("is currently locked")
							? "This loan is currently being edited by Logan Finance. Once Logan Finance exits the loan, you will be able to navigate the loan in the system and the data displayed will be updated as needed."
							: error.includes("Something went wrong")
							? "Updates Not Received by LOS. Please Try Again."
							: error.includes("provide a value")
							? "Please provide a value for all fields highlighted above."
							: error
					}
				/>
			)}

			{/* footer buttons */}
			<Stack direction="row" className={styles.footerContainer}>
				<SecondaryButton
					text="Previous"
					startIcon={<ArrowBackOutlined className={styles.prevIcon} />}
					onClick={handlePrev}
				/>
				<PrimaryButton
					text="Save and Continue"
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={checkClosingAndPurchaseCloseDate}
				/>
			</Stack>

			{/* modals */}
			<LoaderModal open={isFetchingFields || isUpdatingFields} />
			<HelpTextModal
				open={helpTextOpen}
				modalText={helpText}
				extraHelpText={extraHelpText}
				handleClose={handleCloseHelpText}
			/>
			<ConfirmationModal
				open={confirmModalVisible}
				text={confirmModalText}
				confirmText="Yes"
				handleConfirm={handleSubmitConfirmModal}
				closeText="No"
				handleClose={handleCloseConfirmModal}
			/>
		</Stack>
	);
};

export default LoanFeeApplication;
