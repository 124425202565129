import React from "react";
import { useNavigate } from "react-router-dom";

// mui components
import { TableCell, TableRow } from "@mui/material";

// custom components
import Body2 from "../../typography/body-02";

// custom styles
import styles from "../index.module.scss";

const RateLockRow = ({ row }) => {
	const navigate = useNavigate();

	const loanStatus =
		row?.fields["Loan.LoanFolder"] === "Portal - Not Submitted"
			? "Started - Not Submitted"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Started" &&
			  !row?.fields["Fields.TPO.X90"]
			? "Started - Not Submitted"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Started"
			? "Loan Submitted"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Loan Submitted"
			? "Loan Submitted"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Review Submission"
			? "Review Submission"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] ===
			  "Incomplete Credit Pkg"
			? "Incomplete Credit Pkg"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Recd Cred Pkg"
			? "Rec'd Credit Pkg"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Review Cred Pkg"
			? "Rec'd Credit Pkg"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "File Setup"
			? "File Setup"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Submit to UW"
			? "Submitted to UW"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "In UWing Review"
			? "In UWing Review"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Suspended"
			? "In UWing Review"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Cond'l Approval"
			? "Cond'l Approval"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Recd Conditions"
			? "Condition Review"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "UW Cond Review"
			? "Condition Review"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "QC Requested"
			? "Condition Review"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "QC Complete"
			? "Condition Review"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "CTC"
			? "Clear to Close"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Closing Prep"
			? "Closing Docs"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Closing"
			? "Closing"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Funding Prep"
			? "Wire Sent"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Funding"
			? "Funded"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Recd Closing Pkg"
			? "Rec'd Closing Pkg"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Review Closing Pkg"
			? "Review Closing Pkg"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Purch Suspended"
			? "Purch Suspended"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] ===
			  "Recd Purch Conditions"
			? "Rec'd Purch Conditions"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] ===
			  "Review Purch Conditions"
			? "Review Purch Conditions"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Cleared 4 Purch"
			? "Cleared 4 Purch"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Create PA"
			? "Create PA"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Request Wire"
			? "Request Wire"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Purchase" &&
			  "Purchase";

	let daysToExpiryValue;

	if (
		row?.fields["Fields.MS.LOCKDAYS"] !== "" &&
		row?.fields["Fields.MS.LOCKDAYS"]?.split(".")[0] !== "0"
	) {
		daysToExpiryValue = row?.fields["Fields.MS.LOCKDAYS"]?.split(".")[0];
	} else {
		daysToExpiryValue = "";
	}

	return (
		<TableRow
			hover
			tabIndex={-1}
			key={row.id}
			onClick={() => navigate(`/loan-summary/${row?.loanGuid || row?.loanId}`)}
		>
			{/* loan number */}
			<TableCell key={row.id}>
				<Body2
					text={row?.fields["Loan.LoanNumber"]}
					error={row?.fields["Loan.LoanFolder"] === "Portal - Not Submitted"}
					isLink={true}
				/>
			</TableCell>

			{/* expiration date */}
			<TableCell key={row.id}>
				<Body2
					text={
						row?.fields["Fields.762"] !== ""
							? row?.fields["Fields.762"]?.split(" ")[0]
							: ""
					}
				/>
			</TableCell>

			{/* days to expiry */}
			<TableCell key={row.id}>
				<Body2 text={daysToExpiryValue} />
			</TableCell>

			{/* loan program */}
			<TableCell key={row.id} className={styles.expandWidth}>
				<Body2 text={row?.fields["Fields.1401"]} />
			</TableCell>

			{/* borrower name */}
			<TableCell key={row.id} className={styles.expandWidth}>
				<Body2 text={row?.fields["Loan.BorrowerName"]} />
			</TableCell>

			{/* loan folder */}
			<TableCell key={row.id}>
				<Body2 text={row?.fields["Loan.LoanFolder"]} />
			</TableCell>

			{/* loan status */}
			{/* <TableCell key={row.id}>
				<Body2 text={loanStatus || ""} />
			</TableCell> */}
		</TableRow>
	);
};

export default RateLockRow;
